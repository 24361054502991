let previousVisibleSectionId = null;

function getIdOfVisibleSection() {
    const sections = document.querySelectorAll('section');
    let visibleSectionId = null;

    // Loop through each section
    for (const section of sections) {
        // Get the bounding rectangle of the section
        const rect = section.getBoundingClientRect();
        
        // Check if any part of the section is visible
        if (
            (rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
            (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) && rect.top <= 0) ||
            (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.top >= 0 && rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) - 1)
        ) {
            // Set the visible section ID if not already set
            if (!visibleSectionId) {
                visibleSectionId = section.id;
            }
            break; // Break out of the loop after finding the first visible section
        }
    }

    return visibleSectionId;
}


function updateActiveSectionOnScroll() {
    const visibleSectionId = getIdOfVisibleSection();
    
    if (visibleSectionId !== previousVisibleSectionId && visibleSectionId !== null) {
        // Remove active class from previous navigation item
        const previousNavItem = document.querySelector(`nav a[href="#${previousVisibleSectionId}"]`);
        if (previousNavItem) {
            previousNavItem.classList.remove('active');
        }

        // Add active class to current navigation item
        const currentNavItem = document.querySelector(`nav a[href="#${visibleSectionId}"]`);
        if (currentNavItem) {
            currentNavItem.classList.add('active');
        }

        previousVisibleSectionId = visibleSectionId;
    }
}

window.addEventListener('scroll', updateActiveSectionOnScroll);